// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// app
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

// components
import Layout from '../layout/index'
import Element from 'material/src/element'
import Button from 'material/src/button'
import Drawer from 'material/src/drawer'
import Dialog from 'material/src/dialog'

// views
import Subscription from '../list/subscription'
import Giftcards from '../list/giftcards'
import Users from '../list/user'
import AddUser from '../form/adduser'
import Contributor from '../view/contributor'
import Account from '../view/account'

// icons
import iconNavi from '../icon/navi.svg'
import iconPosted from '../icon/inbox.svg'
import iconPress from '../icon/press.svg'
import iconDigger from '../icon/digger.svg'
import iconFriends from '../icon/friends.svg'
import iconPremium from '../icon/premium.svg'
import iconAdmin from '../icon/admin.svg'
import iconRegistered from '../icon/registered.svg'
import iconDownload from '../icon/download.svg'
import iconGiftcard from '../icon/giftcard.svg'
import iconPromotions from '../icon/promotions.svg'

import iconCheck from '../icon/check.svg'
import iconPending from '../icon/hourglas.svg'
import iconDelete from '../icon/delete.svg'
// import iconBroken from '../icon/problem.svg'
import iconRejected from '../icon/close.svg'

import iconDeleted from '../icon/trash.svg'

// var adduser = from './'

// define contants
const TITLE = 'Accounts'

// console.log('layout --', document.body)

/**
 * Application Layout
 */

const defaults = {
  class: 'accounts',
  tag: 'div',
  roles: ['admin', 'registered', 'premium', 'digger', 'press', 'friends', 'deleted'],
  subscription: {
    status: ['subscription', 'active', 'pending', 'awaiting', 'trialing', 'canceled', 'deleted']
  },
  layout: [
    [Element, { class: 'appbar' },
      [Button, 'menu-navi', { class: 'navi', icon: iconNavi, size: 'small', style: 'action' }],
      [Element, 'title', { class: 'title', text: TITLE }]
    ],
    [Drawer, 'navi', { css: 'drawer-persistent', type: 'persistent', open: true },
      [Element, { class: 'group' },
        [Button, 'registered', { icon: iconRegistered, label: 'Registered', name: 'registered', title: 'Registered' }],
        [Button, 'press', { icon: iconPress, label: 'Press', name: 'press', title: 'Press' }],
        [Button, 'premium', { icon: iconPremium, label: 'Premium', name: 'premium', title: 'Premium' }],
        [Button, 'digger', { icon: iconDigger, label: 'Digger', name: 'digger', title: 'Digger' }],
        [Button, 'friends', { icon: iconFriends, label: 'Friends', name: 'friends', title: 'Friends' }],
        [Button, 'admin', { icon: iconAdmin, label: 'Admin', name: 'admin', title: 'Admin' }],
        [Button, 'erased', { icon: iconDelete, label: 'Deleted', name: 'erased', title: 'Deleted' }]
      ],
      [Element, { class: 'divider' }],
      [Element, { class: 'group workflow' },
        [Button, 'subscription', { icon: iconPosted, label: 'Subscriptions', name: 'subscription', title: 'Subscriptions' }],
        [Button, 'active', { icon: iconCheck, label: 'Active', name: 'active', title: 'Active Subscriptions' }],
        [Button, 'pending', { icon: iconPending, label: 'Pending', name: 'pending', title: 'Pending Subscriptions' }],
        [Button, 'trialing', { icon: iconPending, label: 'Trialing', name: 'trialing', title: 'Trialing Subscriptions' }],
        [Button, 'canceled', { icon: iconRejected, label: 'Canceled', name: 'canceled', title: 'Canceled Subscriptions' }],
        [Button, 'deleted', { icon: iconDeleted, label: 'Deleted', name: 'deleted', title: 'Deleted Subscriptions' }]
      ],
      [Element, { class: 'divider' }],
      [Element, { class: 'group workflow' },
        [Button, 'giftcard', { icon: iconGiftcard, label: 'Gift Cards', name: 'giftcard', title: 'Gift Cards' }],
        [Button, 'promotions', { icon: iconPromotions, label: 'Promotions', name: 'promotions', title: 'Promotions' }],
        [Button, 'awaiting', { icon: iconPending, label: 'Awaiting', name: 'awaiting', title: 'Awaiting giftcards' }]
      ],
      [Element, { class: 'separator' }],
      [Element, { tag: 'form', method: 'get', action: '/user/download' },
        // [Element, { tag: 'input', type: 'hidden', name: 'report', value: 'mailing' }],
        [Button, 'download', { icon: iconDownload, class: 'download', label: 'Download Users', title: 'Admin', type: 'submit' }]
      ]
    ],
    [Users, 'users', {}],
    [Subscription, 'subscriptions', {}],
    [Giftcards, 'giftcards', {}],
    [Contributor, 'contributor', {}],
    [Account, 'account', {}]
  ],
  events: [
    // layout
    ['ui.menu-navi.click', 'ui.navi.toggle'],
    ['ui.navi.element.click', 'onNaviSelect'],
    // ['ui.download.click', 'downloadUsers'],

    // list
    ['ui.users.select', 'select'],
    ['ui.users.addUser', 'addUser'],
    ['ui.users.searching', 'searching'],

    // subscription list
    ['ui.subscriptions.select', 'subscriptionSelect'],
    ['ui.giftcards.select', 'giftcardsSelect'],
    ['ui.promotions.select', 'promotionsSelect'],

    // form
    ['ui.account.updated', 'ui.list.update']
  ]
}

class App {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, attach, emitter, context, build, display)

    this.build()
    this.setup()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    // console.log('build', this.options.layout)
    this.layout = new Layout(this.options.layout, this.options.container)

    this.ui = this.layout.component
  }

  setup () {
    this.getContext()
    console.log('context', this.context)
    if (this.context.user) {
      this.ui.users.id = this.context.user._id
      setTimeout(() => {
        // console.log(this.context.user)

        this.ui.contributor.set(this.context.user._id)
        this.ui.account.set(this.context.user)
      }, 300)
    }

    if (this.context.navi) {
      this.selectNavi(this.context.navi)
    }

    this.getNaviCount()
  }

  getSubscriptionCount () {

  }

  getNaviCount () {
    const roles = this.options.roles
    for (var i = 0; i < roles.length; i++) {
      this.fetchNaviCount(roles[i])
    }

    for (var i = 0; i < this.options.subscription.status.length; i++) {
      this.fetchSubscriptionCount(this.options.subscription.status[i])
    }

    this.fetchGiftcardsCount()
    this.fetchPromotionsCount()
  }

  fetchSubscriptionCount (status) {
    // console.log('status', status)
    let filter = ''

    if (status !== 'subscription') {
      filter = 'status=' + status + '&'
    }

    fetch('/subscription/list?' + filter + 'count=1', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('count', data)
      this.updateNaviCount(status, data.count)
    })
  }

  fetchGiftcardsCount () {
    // console.log('status', status)
    const filter = ''

    fetch('/giftcard/list?' + filter + 'count=1&type=stripe', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('count', data)
      this.updateNaviCount('giftcard', data.count)
    })
  }

  fetchPromotionsCount () {
    // console.log('status', status)
    const filter = ''

    fetch('/giftcard/list?' + filter + 'count=1&type=promotion', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('count', data)
      this.updateNaviCount('promotions', data.count)
    })
  }

  fetchNaviCount (name) {
    // console.log('fetchCount', route)
    fetch('/user/list?role=' + name + '&count=1').then((resp) => {
      return resp.json()
    }).then((data) => {
      // console.log('data', data.count)
      this.count = data.count
      this.updateNaviCount(name, data.count)
    }).catch(function (e) {
      // console.log('error', e.message)
    })
  }

  updateNaviCount (name, count) {
    // console.log('count', name, count)

    if (!this.ui[name]) return

    if (count === undefined) {
      count = 0
    }

    let info = this.ui[name].element.querySelector('.count')

    if (!info) {
      info = document.createElement('span')
      info.classList.add('count')
    }

    info.innerHTML = count
    if (this.ui[name].element.appendChild) {
      this.ui[name].element.appendChild(info)
    }
  }

  onNaviSelect (e) {
    // console.log('naviSelet', e.target)
    if (e.target.matches('BUTTON')) {
      const name = e.target.name

      this.selectNavi(name)
    }
  }

  selectNavi (name) {
    // console.log('name', name)
    this.ui.users.ui.title.innerHTML = this.ui[name].element.title
    if (this.selectedMenu) {
      this.selectedMenu.classList.remove('selected')
      this.ui.users.element.classList.remove(this.selectedMenu.name)
    }

    this.selectedMenu = this.ui[name].element

    this.selectedMenu.classList.add('selected')

    this.updateList(name)
  }

  updateList (name) {
    // console.log('updateList', name)
    this.setContext('navi', name)

    this.ui.users.element.classList.add(name)
    // console.log('name', name)

    if (this.options.subscription.status.indexOf(name) > -1) {
      // console.log('subscription')
      this.ui.users.hide()
      this.ui.giftcards.hide()
      this.ui.subscriptions.show()
      this.ui.subscriptions.page = 1
      if (name !== 'subscription') {
        this.ui.subscriptions.setStatus(name)
      } else {
        this.ui.subscriptions.setStatus(null)
      }

      this.ui.subscriptions.request()
    } else if (name === 'giftcard') {
      // console.log('subscription')
      this.ui.users.hide()
      this.ui.subscriptions.hide()
      this.ui.giftcards.type = 'stripe'
      this.ui.giftcards.show()
      this.ui.giftcards.page = 1

      this.ui.giftcards.request()
    } else if (name === 'promotions') {
      console.log('promotions')
      this.ui.users.hide()
      this.ui.subscriptions.hide()
      this.ui.giftcards.type = 'promotion'
      this.ui.giftcards.show()
      this.ui.giftcards.page = 1
      this.ui.giftcards.request()
    } else {
      if (name === 'erased') {
        name = 'deleted'
      }

      this.ui.users.show()
      this.ui.giftcards.hide()
      this.ui.subscriptions.hide()
      this.ui.users.page = 1

      this.ui.users.setRole(name)

      // this.ui.users.body.innerHTML = ''

      this.ui.users.request()
    }

    this.navi = name

    // console.log('route', route)
  }

  addUser (role) {
    // console.log('adduser', this.ui)
    this.addUserDialog = new Dialog({
      class: 'adduser',
      container: this.options.container,
      name: 'share',
      layout: [
        [Element, 'head', { class: 'head' },
          [Element, 'title', { class: 'title', text: 'Add User' }],
          [Button, 'close', { class: 'close' }]
        ],
        [Element, 'body', { class: 'body' }]
      ],
      events: [
        ['ui.ok.click', 'ok'],
        ['ui.cancel.click', 'cancel'],
        ['ui.close.click', 'close'],
        ['ui.facebook.click', 'close'],
        ['ui.twitter.click', 'close']
      ]
    })

    this.addUserDialog.show()

    const user = new AddUser({
      container: this.addUserDialog.ui.body
    }).on('close', () => {
      // console.log('close add user')
      this.addUserDialog.destroy()
    }).on('updated', (info) => {
      // console.log('updated', info)
      this.ui.users.request()
      this.addUserDialog.destroy()
    })

    user.set({
      username: '',
      password: '',
      role
    })

    // console.log('add user dialog', this.addUserDialog)
  }

  searching () {
    console.log('searching')
  }

  select (id) {
    // console.log('select', id, this.ui.users.dataStore[id])
    this.setContext('user', this.ui.users.dataStore[id])

    this.ui.contributor.set(this.ui.users.dataStore[id])
    this.ui.account.set(this.ui.users.dataStore[id])
  }

  subscriptionSelect (id) {
    // console.log('select', id, this.ui.subscriptions.dataStore[id])

    this.setContext('subscription', this.ui.subscriptions.dataStore[id])

    this.ui.contributor.set(this.ui.subscriptions.dataStore[id])
    this.ui.account.set(this.ui.subscriptions.dataStore[id])
  }

  giftcardsSelect (id) {
    // console.log('select', id, this.ui.users.dataStore[id])
    // this.setContext('giftcards', this.ui.users.dataStore[id])

    this.ui.contributor.set(this.ui.giftcards.dataStore[id])
    this.ui.account.set(this.ui.giftcards.dataStore[id])
  }

  promotionsSelect (id) {
    // console.log('select', id, this.ui.users.dataStore[id])
    // this.setContext('giftcards', this.ui.users.dataStore[id])

    this.ui.contributor.set(this.ui.giftcards.dataStore[id])
    this.ui.account.set(this.ui.giftcards.dataStore[id])
  }

  downloadUsers (e) {
    e.stopPropagation()
    console.log('download')
    // window.location.href = '/user/list?report=mailing'
  }

  command () {}
}

export default App
