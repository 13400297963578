import Login from './login/login'
import Account from './account/account'
import asset from './module/asset'
export default {
  initUser (user) {
    // console.log('initUser', user)
    this.user = user

    if (user && user._id) {
      this.initStart()
    } else {
      this.signIn()
    }

    this.layout.get('account').on('click', () => {
      if (this.user && this.user._id) {
        this.showAccount()
      } else {
        this.signIn()
      }
    })
  },

  initAsset (info) {
    // console.log('asset', info)
    if (info) {
      this.asset = info
      this.ui.player.asset = info
      asset.set(info)
      document.body.dataset.asset = info.url
    }
  },

  /**
   * [signIn description]
   * @param  {Function} cb [description]
   * @return {[type]}      [description]
   */
  signIn (cb) {
    new Login().on('success', (user) => {
      this.user = user
      this.initStart()
    })
  },

  showAccount () {
    this.account = new Account().on('signedout', () => {
      // console.log('signout')
      this.user = null
      window.location.reload()
    }).set(this.user)
  },

  isAuthenticated () {
    // console.log('isAuthenticated')
    fetch('/account/connected', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('data', data)
      this.initUser(data.user)
      this.initAsset(data.asset)
    })
  }
}
