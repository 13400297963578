// import { parse } from '../module/user-agent'
import { Component, Element } from 'material'
import { UAParser } from 'ua-parser-js'
import * as css from 'material/src/module/css'

class UserAgent extends Component {
  static defaults = {
    class: 'user-agent',
    layout: [
      [Element, 'device', { class: 'device' }],
      [Element, 'os', { class: 'os' }],
      [Element, 'browser', { class: 'browser' }]
    ]
  }

  set (value) {
    // console.log('set', value)
    if (!value) return

    this.value = value

    const parser = new UAParser(value)
    const agent = parser.getResult()

    if (agent?.os) {
      const os = agent.os?.name
      if (os) css.add(this.ui.os, os.toLowerCase())
      const info = os + ' ' + agent.os?.version
      this.ui.os.title = info
    }

    if (agent?.browser) {
      // console.log('browser name', agent?.browser?.name)
      const browser = agent.browser?.name
      if (browser) css.add(this.ui.browser, browser.toLowerCase())
      const info = agent.browser?.name + ' ' + agent.browser?.version
      this.ui.browser.title = info
    }

    if (agent?.device) {
      const device = agent.device?.type
      const name = device || 'desktop'
      css.add(this.ui.device, name.toLowerCase())
      this.ui.device.title = agent.device?.model || ''
    }
  }

  get () {
    return this.value
  }
}

export default UserAgent
