import Control from 'material/src/class/control'

class Color extends Control {
  static defaults = {
    class: 'color',
    tag: 'div',
    events: [
      ['element.click', 'update']
    ]
  }

  constructor (options) {
    super(options)
    // Create wrapper for positioning
    this.wrapper = document.createElement('div')
    this.wrapper.style.position = 'absolute'
    this.wrapper.style.visibility = 'hidden'

    // Create color input element
    this.picker = document.createElement('input')
    this.picker.type = 'color'

    this.wrapper.appendChild(this.picker)
    document.body.appendChild(this.wrapper)

    // Bind color change event
    this.picker.addEventListener('change', (e) => {
      this.set(e.target.value)
      this.wrapper.style.visibility = 'hidden'
    })
  }

  update () {
    // Position wrapper next to element
    const rect = this.element.getBoundingClientRect()
    this.wrapper.style.top = `${rect.bottom + window.scrollY}px`
    this.wrapper.style.left = `${rect.left + window.scrollX}px`
    this.wrapper.style.visibility = 'visible'

    // Trigger native color picker
    this.picker.click()
    return this
  }

  set (value) {
    this.color = value
    if (value !== undefined) {
      this.element.style.backgroundColor = value
      this.element.title = value
      this.picker.value = value
    }
    return this
  }

  get () {
    return this.color
  }
}

export default Color
