import Component from 'material/src/class/component'

class Apple extends Component {
  static defaults = {
    class: 'apple'
  }

  set (value) {
    // console.log('apple id', value)
    if (value) {
      this.element.classList.add('show')
    }
  }
}

export default Apple
