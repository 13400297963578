import cookie from './module/cookie'

export default {
  /**
   * [initApps description]
   * @param  {[type]} apps [description]
   * @return {[type]}      [description]
   */
  initApps (apps) {
    console.log('initApps', apps)

    this.active = null
    this.apps = apps
    this.app = {}
    this.appscontainer = {}
    this.container = this.ui.body
    // this.asset = asset

    const name = cookie.get('app') || 'tracks'

    this.initMenu(apps)
    this.initList(apps)
    this.startApp(name, apps)
  },

  startApp (name, apps) {
    // console.log('start', name, apps)

    cookie.set('app', name, 1000 * 24 * 365)
    this.hideApps()
    name = name || apps[0].name

    const active = null
    if (this.active) {
      this.active.hide()
    }

    if (!this.app[name]) {
      const wrapper = this.buildApp(name, apps)
      for (let i = 0; i < apps.length; i++) {
        if (apps[i].name === name) {
          if (apps[i].app) {
            console.log('new app', apps[i])
            const app = new apps[i].app({
              container: wrapper,
              asset: this.asset
            })

            this.app[name] = app
            this.app[name].show()

            if (apps[i].url) {
              app.set(apps[i].url)
            }
          }
        }
      }
    } else {
      // console.log('---')
      this.app[name].show()
      // app.element.src = '/app/' + name;
    }

    document.body.classList.remove('launchpad')

    // this.publish('app.select', name)
    this.ui.body.classList.remove('small-apps')
    this.active = this.app[name]

    this.updateMenu(name)

    return active
  },

  buildApp (name, apps) {
    // console.log('buildApp', name, apps)

    const app = document.createElement('div')
    app.classList.add('app')
    app.classList.add(name)
    this.container.appendChild(app)

    const wrapper = document.createElement('div')
    wrapper.classList.add('wrapper')
    app.appendChild(wrapper)

    const cover = document.createElement('div')
    app.appendChild(cover)
    cover.classList.add('cover')
    cover.addEventListener('click', () => {
      this.startApp(name, apps)
    })

    return wrapper
  },

  selectApp (name) {
    // console.log('select', name)

    this.startApp(name, this.apps, this.asset)

    this.appsList.classList.remove('show')
    document.body.classList.remove('launchpad')

    this.publish('app.select', name)
  },

  /**
   * [showApps description]
   * @return {[type]} [description]
   */
  showApps () {
    // console.log('showApps')

    this.ui.body.classList.add('small-apps')

    for (const name in this.app) {
      if (this.app.hasOwnProperty(name)) {
        const container = this.app[name].container.parentNode
        // container.classList.add('container-small')
        this.app[name].show()
      }
    }
  },

  /**
   * hideApps
   * @return {[type]} [description]
   */
  hideApps () {
    for (const name in this.app) {
      if (this.app.hasOwnProperty(name)) {
        this.app[name].hide()
      }
    }
  }

}
