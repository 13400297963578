import Component from 'material/src/class/component'

class Social extends Component {
  static defaults = {
    class: 'social'
  }

  set (value) {
    // console.log('social.media', value)
    if (value === 'facebook') {
      this.element.classList.add('show')
      this.element.classList.add('facebook')
    }
  }
}

export default Social
