// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// component
import Button from 'material/src/button'
import Element from 'material/src/element'
// import Text from 'material/src/text'
import Textfield from 'material/src/textfield'
import Switch from 'material/src/switch'
import Switcher from '../control/switcher'
import UserAgent from '../field/user-agent'
// form

import build from 'material/src/form/build'
import data from 'material/src/form/data'
import controller from 'material/src/form/controller'
import render from 'material/src/form/render'
import submit from 'material/src/form/submit'
import update from 'material/src/form/update'
import cancel from 'material/src/form/cancel'

const defaults = {
  class: 'user',
  sysinfo: ['_id', 'uuid'],
  action: '/user/',
  update: {
    method: 'put'
  },
  autocomplete: 'off',
  layout: [
    [Element, 'body', { class: 'body' },
      // [Element, 'image', { class: 'image' },
      //   [Text, 'image-file', { text: 'Image File' }]
      // ],
      // [Textfield, 'info.created', { label: 'Created Date' }],
      [Element, { class: 'group row' },
        [Textfield, 'info.username', { label: 'Username', autocomplete: 'off' }],
        [Textfield, 'info.code', { class: 'noflex code', label: 'code' }]
      ],
      [Element, { class: 'group' },
      // [Textfield, 'info.password', { label: 'Password', type: 'password', autocomplete: 'off', disabled: true }],
        [Switcher, 'info.role', { label: 'Role', size: 'small', list: ['registered', 'press', 'premium', 'digger', 'friends', 'admin', 'deleted'] }],
        [Switch, 'info.enabled', { label: 'enabled' }]
      ]
    ],
    [Element, 'user-agent', { class: 'agent' },
      [UserAgent, 'info.ua']
    ],
    [Element, 'foot', { class: 'foot' },
      [Element, 'divider', { class: 'divider', flex: '1' }],
      [Button, 'cancel', { text: 'Cancel', enabled: false }],
      [Button, 'submit', { text: 'Apply', type: 'submit', color: 'primary', enabled: false }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['form.cancel', 'cancel'],

    // mode
    ['mode', 'changeMode']
  ]
}

class Form {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach,
      build, data, render, submit, update, cancel, controller
    )

    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.attach()
    this.initController()

    return this
  }

  set (user) {
    // console.log('set', user)

    if (!user) return

    this.setMode('read')

    fetch(this.options.action + user.user_id, {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((info) => {
      // console.log('info', info)
      this.info = info
      this.render(info)
    })
  }
}

export default Form
