export default [
  ['USA', 'United States'],
  ['FRA', 'France'],
  ['GBR', 'United Kingdom'],
  ['RUS', 'Russia'],
  ['BRA', 'Brazil'],
  ['TUR', 'Turkey'],
  ['DEU', 'Germany'],
  ['CAN', 'Canada'],
  ['MEX', 'Mexico'],
  ['ESP', 'Spain'],
  ['AFG', 'Afghanistan'],
  ['ALB', 'Albania'],
  ['DZA', 'Algeria'],
  ['ASM', 'American Samoa'],
  ['AND', 'Andorra'],
  ['AGO', 'Angola'],
  ['AIA', 'Anguilla'],
  ['ATA', 'Antarctica'],
  ['ARG', 'Argentina'],
  ['ARM', 'Armenia'],
  ['ABW', 'Aruba'],
  ['AUS', 'Australia'],
  ['AUT', 'Austria'],
  ['EAH', 'Austro Hungarian Empire'],
  ['AZE', 'Azerbaijan'],
  ['BHS', 'Bahamas'],
  ['BHR', 'Bahrain'],
  ['BGD', 'Bangladesh'],
  ['BRB', 'Barbados'],
  ['BEC', 'Bechuanaland'],
  ['BLR', 'Belarus'],
  ['CBE', 'Belgian Congo'],
  ['BEL', 'Belgium'],
  ['BLZ', 'Belize'],
  ['BEN', 'Benin'],
  ['BMU', 'Bermuda'],
  ['BTN', 'Bhutan'],
  ['BOL', 'Bolivia'],
  ['BIH', 'Bosnia'],
  ['BWA', 'Botswana'],
  ['BOW', 'Bowie Island'],
  ['BRA', 'Brazil'],
  ['BRI', 'British India'],
  ['BRN', 'Brunei'],
  ['BGR', 'Bulgaria'],
  ['BFA', 'Burkina Faso'],
  ['BDI', 'Burundi'],
  ['KHM', 'Cambodia'],
  ['CMR', 'Cameroon'],
  ['CAN', 'Canada'],
  ['CPV', 'Cape Verde'],
  ['CAF', 'Central African'],
  ['CEY', 'Ceylon'],
  ['TCD', 'Chad'],
  ['CHL', 'Chile'],
  ['CHN', 'China'],
  ['MOV', 'Cinema island'],
  ['CCK', 'Cocos Islands'],
  ['COL', 'Colombia'],
  ['COM', 'Comoros'],
  ['COG', 'Congo'],
  ['COK', 'Cook Islands'],
  ['CRI', 'Costa Rica'],
  ['HRV', 'Croatia'],
  ['CUB', 'Cuba'],
  ['CYP', 'Cyprus'],
  ['CZE', 'Czech Republic'],
  ['TCH', 'Czechoslovakia'],
  ['COD', 'D.R. Congo'],
  ['DAH', 'Dahomey'],
  ['DNK', 'Denmark'],
  ['DJI', 'Djibouti'],
  ['DMA', 'Dominica'],
  ['DOM', 'Dominican Republic'],
  ['DEI', 'Dutch East Indies'],
  ['RDA', 'East Germany'],
  ['PAE', 'East Pakistan'],
  ['ECU', 'Ecuador'],
  ['EGY', 'Egypt'],
  ['SLV', 'El Salvador'],
  ['GNQ', 'Equatorial Guinea'],
  ['ERI', 'Eritrea'],
  ['EST', 'Estonia'],
  ['ETH', 'Ethiopia'],
  ['FLK', 'Falkland Islands'],
  ['FRO', 'Faroe Islands'],
  ['FJI', 'Fiji'],
  ['FIN', 'Finland'],
  ['FRA', 'France'],
  ['AEF', 'French Equatorial Africa'],
  ['GUF', 'French Guiana'],
  ['PYF', 'French Polynesia'],
  ['ATF', 'French Southern Territories'],
  ['AOF', 'French West Africa'],
  ['FWI', 'French West Indies'],
  ['GAB', 'Gabon'],
  ['GMB', 'Gambia'],
  ['GEO', 'Georgia'],
  ['DEU', 'Germany'],
  ['GHA', 'Ghana'],
  ['GIB', 'Gibraltar'],
  ['GOL', 'Gold Coast'],
  ['GRC', 'Greece'],
  ['GRL', 'Greenland'],
  ['GRD', 'Grenada'],
  ['GLP', 'Guadeloupe'],
  ['GUM', 'Guam'],
  ['GTM', 'Guatemala'],
  ['GIN', 'Guinea'],
  ['GNB', 'Guinea-Bissau'],
  ['GUY', 'Guyana'],
  ['HTI', 'Haiti'],
  ['HWA', 'Hawai'],
  ['HND', 'Honduras'],
  ['HKG', 'Hong Kong'],
  ['HUN', 'Hungary'],
  ['ISL', 'Iceland'],
  ['IND', 'India'],
  ['ICH', 'Indochina'],
  ['IDN', 'Indonesia'],
  ['IRN', 'Iran'],
  ['IRQ', 'Iraq'],
  ['IRL', 'Ireland'],
  ['IMN', 'Isle of Man'],
  ['ISR', 'Israel'],
  ['ITA', 'Italy'],
  ['CIV', 'Ivory Coast'],
  ['JAM', 'Jamaica'],
  ['JPN', 'Japan'],
  ['JOR', 'Jordan'],
  ['KAM', 'Kamerun'],
  ['KAZ', 'Kazakhstan'],
  ['KEN', 'Kenya'],
  ['KIR', 'Kiribati'],
  ['KOR', 'Korea'],
  ['KOS', 'Kosovo'],
  ['KWT', 'Kuwait'],
  ['KGZ', 'Kyrgyzstan'],
  ['LAO', 'Laos'],
  ['LVA', 'Latvia'],
  ['LBN', 'Lebanon'],
  ['LSO', 'Lesotho'],
  ['LBR', 'Liberia'],
  ['LBY', 'Libya'],
  ['LTU', 'Lithuania'],
  ['LUX', 'Luxembourg'],
  ['MKD', 'Macedonia'],
  ['MDG', 'Madagascar'],
  ['MWI', 'Malawi'],
  ['MYS', 'Malaysia'],
  ['MDV', 'Maldives'],
  ['MLI', 'Mali'],
  ['MLT', 'Malta'],
  ['MTQ', 'Martinique'],
  ['MRT', 'Mauritania'],
  ['MUS', 'Mauritius'],
  ['MES', 'Mesopotamia'],
  ['MEX', 'Mexico'],
  ['MDA', 'Moldova'],
  ['MCO', 'Monaco'],
  ['MNG', 'Mongolia'],
  ['MON', 'Montenegro'],
  ['MAR', 'Morocco'],
  ['MOZ', 'Mozambique'],
  ['MMR', 'Myanmar'],
  ['NAM', 'Namibia'],
  ['NRU', 'Nauru'],
  ['NPL', 'Nepal'],
  ['NLD', 'Netherlands'],
  ['ANT', 'Netherlands Antilles'],
  ['NCL', 'New Caledonia'],
  ['NZL', 'New Zealand'],
  ['NIC', 'Nicaragua'],
  ['NER', 'Niger'],
  ['NGA', 'Nigeria'],
  ['PRK', 'North Korea'],
  ['RHN', 'North Rhodesia'],
  ['NOR', 'Norway'],
  ['OMN', 'Oman'],
  ['PAK', 'Pakistan'],
  ['PSE', 'Palestine'],
  ['PAN', 'Panama'],
  ['PNG', 'Papua New Guinea'],
  ['PRY', 'Paraguay'],
  ['PIA', 'Persia'],
  ['PER', 'Peru'],
  ['PHL', 'Philippines'],
  ['POL', 'Poland'],
  ['PRT', 'Portugal'],
  ['PRI', 'Puerto Rico'],
  ['QAT', 'Qatar'],
  ['ROU', 'Romania'],
  ['RUS', 'Russia'],
  ['RWA', 'Rwanda'],
  ['REU', 'Réunion'],
  ['SHN', 'Saint Helena'],
  ['KN1', 'Saint Kitts and Nevis'],
  ['WSM', 'Samoa'],
  ['SMR', 'San Marino'],
  ['STP', 'Sao Tome and Principe'],
  ['SAU', 'Saudi Arabia'],
  ['SEN', 'Senegal'],
  ['SCG', 'Serbia'],
  ['SYC', 'Seychelles'],
  ['SLE', 'Sierra Leone'],
  ['SGP', 'Singapore'],
  ['SVK', 'Slovakia'],
  ['SVN', 'Slovenia'],
  ['SLB', 'Solomon Islands'],
  ['SOM', 'Somalia'],
  ['SML', 'Somaliland'],
  ['ZAF', 'South Africa'],
  ['SAR', 'South Arabia'],
  ['RHS', 'South Rhodesia'],
  ['SSD', 'South Sudan'],
  ['ESP', 'Spain'],
  ['LKA', 'Sri Lanka'],
  ['SDN', 'Sudan'],
  ['SRB', 'Serbie'],
  ['SUR', 'Suriname'],
  ['SWZ', 'Swaziland'],
  ['SWE', 'Sweden'],
  ['CHE', 'Switzerland'],
  ['SYR', 'Syria'],
  ['TWN', 'Taiwan'],
  ['TJK', 'Tajikistan'],
  ['TGY', 'Tanganyika'],
  ['TZA', 'Tanzania'],
  ['THA', 'Thailand'],
  ['TLS', 'Timor-Leste'],
  ['TGO', 'Togo'],
  ['TON', 'Tonga'],
  ['TTO', 'Trinidad and Tobago'],
  ['TUN', 'Tunisia'],
  ['TUR', 'Turkey'],
  ['TKM', 'Turkmenistan'],
  ['VIR', 'U.S. Virgin Islands'],
  ['UGA', 'Uganda'],
  ['UKR', 'Ukraine'],
  ['ARE', 'United Arab Emirates'],
  ['GBR', 'United Kingdom'],
  ['USA', 'United States'],
  ['UMI', 'United States Minor Outlying Islands'],
  ['UPP', 'Upper Volta'],
  ['URY', 'Uruguay'],
  ['UZB', 'Uzbekistan'],
  ['VUT', 'Vanuatu'],
  ['VEN', 'Venezuela'],
  ['VNM', 'Vietnam'],
  ['EWI', 'West Indies'],
  ['PAW', 'West Pakistan'],
  ['ESH', 'Western Sahara'],
  ['wwi', 'Wise Women Island'],
  ['YEM', 'Yemen'],
  ['YUG', 'Yugoslavia'],
  ['ZAI', 'Zaire'],
  ['ZMB', 'Zambia'],
  ['ZWE', 'Zimbabwe'],
  ['ALA', 'Åland Islands']
]
